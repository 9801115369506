import styled from 'styled-components';

import media from '../../shared/media';

export const Wrapper = styled.section`
  ${media.tablet} {
    :last-of-type h1 {
      font-size: 3rem;
    }
    :last-of-type > div > div > div > p {
      font-size: 30px;
    }
  }

  ${({isComptia}) =>
    isComptia &&
    `


    ${Content} {
      max-width: 100%;
      text-align: start !important;
    }
    .hero-alignment-left & {
      text-align: left;
      margin: 80px auto 0 0;
    }

    h1, h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }

    h4 {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    ${Content} p {
      font-weight: 200;
      font-size: 16px !important;
      line-height: 24px;
    }

    ol, ul{
      padding: 0 0 0 15px;
      margin: 5px 0;

      li{
        font-weight: 300;
        font-size: 20px !important;
        line-height: 24px;
      }
    }

    ${media.tablet} {
      h1 {
      font-size: 30px;
      }

      h4 {
        margin-top: 3rem;
        margin-bottom: 2rem;
      }
    
      ${Content} p {
        font-weight: 200;
        font-size: 20px !important;
        line-height: 32px;
      }
    }
    `}

  ${media.tablet} {
    padding: 140px 0 40px;
  }
  padding: 40px 0 40px;

  .layout-template-services & {
    ${media.tablet} {
      padding: 220px 0 40px;
    }
  }
`;

export const Title = styled.h1`
  font-family: 'Noto Serif';
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin: 0;
  text-align: center;
  color: ${({theme}) => theme.colors.textColor};
  transition: color 0.2s;

  ${media.tablet} {
    font-size: 64px;
    line-height: 80px;
  }

  p {
    margin: 0;
  }

  .hero-alignment-left & {
    text-align: left;
  }

  .hero-alignment-left & {
    text-align: left;
  }

  em {
    color: ${({theme}) => theme.colors.accent};
    font-style: normal;
  }
`;

export const Content = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: -0.5px;
  color: ${({theme}) => theme.colors.textColor};
  max-width: ${(props) => (props.isECPage ? '18em' : '16em')};
  margin: 20px auto 0;
  text-align: center;

  p {
    margin-bottom: 0;
  }

  ${media.tablet} {
    font-size: 40px;
    line-height: 48px;
  }

  .hero-alignment-left & {
    text-align: left;
    margin: 20px auto 0 0;
  }

  ${({isContactPage}) =>
    isContactPage &&
    `
    p {
      font-size: 24px !important;
      max-width: 100% !important;
    }
    
    

    p > a {
      color: #78C257;
      text-decoration: none;

      .theme-dark & {
        color: #78C257
      }
    }
    
  `}

  ${({isInsight}) =>
    isInsight &&
    `
    max-width: 100%;
    
    .hero-alignment-left & {
      text-align: left;
      margin: 80px auto 0 0;
    }

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }
    p {
      font-weight: 200;
      font-size: 20px !important;
      line-height: 32px;
    }
    ol{
      padding: 0 0 0 15px;
      margin: 5px 0;

      li{
        font-weight: 600;
        font-size: 20px !important;
        line-height: 24px;
      }
    }
    `}

${({isPrivacyPolicy}) =>
    isPrivacyPolicy &&
    `
    max-width: 100%;
    text-align: start !important;
    
    .hero-alignment-left & {
      text-align: left;
      margin: 80px auto 0 0;
    }

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }
    p {
      font-weight: 200;
      font-size: 20px !important;
      line-height: 32px;
    }
    ol, ul{
      padding: 0 0 0 15px;
      margin: 5px 0;

      li{
        font-weight: 300;
        font-size: 20px !important;
        line-height: 24px;
      }
    }
    `}
`;

export const ContentWrapper = styled.div`
  /* adding a hr line for insights page */
  & hr {
    position: relative;
    top: 15px;
  }
`;

export const ContentImage = styled.img`
  width: 100%;
  object-fit: contain;
  max-height: 50vh;
`;

export const Image = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  background-image: ${(props) => props.image};
  background-position: right top;
  background-size: contain;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;
  z-index: -1;

  img {
    height: 100%;
    opacity: 0.07;

    .theme-dark & {
      filter: invert(1);
      opacity: 0.08;
    }
  }
`;
